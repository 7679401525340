<template>
  <div id="sidebar-component">
    <div class="sidebar-wrapper">
      <!-- Sidebar -->
      <nav class="" id="sidebar-content">
        <ul class="nav flex-column">
          <li class="p-3" :class="(this.$route.name.includes('dashboard')) ? 'sidebar-active' : ''">
            <div class="d-flex">
              <b-icon class="mr-4 mt-1" icon="house-door" font-scale="1.5" variant="white"></b-icon>
              <span class="text-secondary">
                <router-link class="nav-link text-secondary mt-2 p-0" :to="{ name: 'dashboard-index' }">
                  {{ $t('sidebar.dashboardMenu') }}
                </router-link>
              </span>
            </div>
          </li>
          <li class="header p-2 ml-2 mt-2">
            <b-icon class="mr-4 mt-1" icon="box-seam" font-scale="1.5" variant="white"></b-icon>
            <span class="text-light">
              {{ $tc('common.order', 0).toUpperCase() }}
            </span>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('order')) ? 'sidebar-active' : ''">
            <div class="ml-5 mt-1">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'order-index' }">
                {{ $tc('common.order', 0) }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('request-cancel')) ? 'sidebar-active' : ''">
            <div class="ml-5 mt-1">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'request-cancel-index' }">
                {{ $t('sidebar.orderMenu.orderCancel') }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('not-conformity')) ? 'sidebar-active' : ''">
            <div class="ml-5 mt-1" :class="(this.$store.getters.userIsReseller) ? 'd-flex' : ''">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'not-conformity-index' }">
                {{ $t('sidebar.orderMenu.orderNotConformity') }}
              </router-link>
              <div class="ml-2 mt-1 text-center" v-if="this.$store.getters.userIsReseller">
                <b-badge :variant="(countOrderNotConformity > 0) ? 'danger' : 'success'">{{ countOrderNotConformity }}</b-badge>
              </div>
            </div>
          </li>
          <li class="header p-2 ml-2 mt-2">
            <b-icon class="mr-4 mt-1" icon="clipboard" font-scale="1.5" variant="white"></b-icon>
            <span class="text-light">{{ $tc('common.quote', 0).toUpperCase() }}</span>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('quote')) ? 'sidebar-active' : ''">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'quote-index' }">
                {{ $tc('common.quote', 0) }}
              </router-link>
            </div>
          </li>
          <li class="header p-2 ml-2 mt-2">
            <b-icon class="mr-4" icon="people" font-scale="1.5" variant="white"></b-icon>
            <span class="text-light">{{ $tc('common.user', 0).toUpperCase() }}</span>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('user')) ? 'sidebar-active' : ''">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'user-index' }">
                <span v-if="$store.getters.userIsAdmin">{{ $tc('common.user', 0) }}</span>
                <span v-else>{{ $t('sidebar.userMenu.userQuote') }}</span>
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('reseller')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'reseller-index' }">
                {{ $tc('common.reseller', 0) }}
              </router-link>
            </div>
          </li>
          <li class="header p-2 ml-2 mt-2" v-if="$store.getters.userIsAdmin">
            <b-icon class="mr-4 mt-1" icon="tags" font-scale="1.5" variant="white"></b-icon>
            <span class="text-light">{{ $tc('common.settings', 0).toUpperCase() }}</span>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('category')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'category-index' }">
                {{ $tc('common.settings', 0) }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('mandrel')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'mandrel-index' }">
                {{ $t('sidebar.categoryMenu.mandrel') }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('orientation')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'orientation-index' }">
                {{ $t('sidebar.categoryMenu.orientation') }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('paper')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'paper-index' }">
                {{ $t('sidebar.categoryMenu.paper') }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('finishing')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'finishing-index' }">
                {{ $t('sidebar.categoryMenu.finishing') }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('dimension')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'dimension-index' }">
                {{ $t('sidebar.categoryMenu.dimension') }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('provide-number-per-rolls')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'provide-number-per-rolls-index' }">
                {{ $t('sidebar.categoryMenu.numberPerRolls') }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('cat-sc')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'cat-sc-index' }">
                {{ $t('sidebar.categoryMenu.categoryScenario') }}
              </router-link>
            </div>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('cat-sc')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'shape-index' }">
                {{ $t('common.shape') }}
              </router-link>
            </div>
          </li>
          <li class="header p-2 ml-2 mt-2" v-if="$store.getters.userIsAdmin">
            <b-icon class="mr-4" icon="folder" font-scale="1.5" variant="white"></b-icon>
            <span class="text-light">
              {{ $t('sidebar.scenarioMenu').toUpperCase() }}
            </span>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('scenario')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'scenario-index' }">
                {{ $t('sidebar.scenarioMenu') }}
              </router-link>
            </div>
          </li>
          <li class="header p-2 ml-2 mt-2" v-if="$store.getters.userIsAdmin">
            <b-icon class="mr-4" icon="flag" font-scale="1.5" variant="white"></b-icon>
            <span class="text-light">
              {{ $t('sidebar.countries').toUpperCase() }}
            </span>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('countries')) ? 'sidebar-active' : ''" v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'countries-index' }">
                {{ $t('sidebar.countries') }}
              </router-link>
            </div>
          </li>
          <li>
            <hr class="separator">
          </li>
          <li class="header p-2 ml-2" v-if="$store.getters.userIsAdmin">
            <b-icon class="mr-4" icon="calculator" font-scale="1.5" variant="white"></b-icon>
            <span class="text-light">{{ $t('sidebar.calculatorMenu.main').toUpperCase() }}</span>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('cms-calculator')) ? 'sidebar-active' : ''"  v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'cms-calculator-index' }">
                {{ $t('sidebar.calculatorMenu.link') }}
              </router-link>
            </div>
          </li>
          <li class="header p-2 ml-2">
            <b-icon class="mr-4" icon="gear" font-scale="1.5" variant="white"></b-icon>
            <span class="text-light">{{ $t('sidebar.configMenu.main') }}</span>
          </li>
          <li class="nav-item p-1 pl-3" :class="(this.$route.name.includes('config')) ? 'sidebar-active' : ''"  v-if="$store.getters.userIsAdmin">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'config-env-index' }">
                {{ $t('sidebar.configMenu.link') }}
              </router-link>
            </div>
          </li>
          <li v-if="$store.getters.userIsReseller" class="nav-item p-1 pl-3" :class="(this.$route.name.includes('profile')) ? 'sidebar-active' : ''">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'reseller-profile' }">
                <span>{{ $t('header.dropdownUser.profilePage') }}</span>
              </router-link>
            </div>
          </li>
          <li v-if="$store.getters.userIsReseller" class="nav-item p-1 pl-3" :class="(this.$route.name.includes('marge-price')) ? 'sidebar-active' : ''">
            <div class="ml-5">
              <router-link class="nav-link text-secondary mt-1 p-0" :to="{ name: 'reseller-marge-price' }">
                <span>{{ $t('pages.margePrice.title') }}</span>
              </router-link>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    props: {
      isMobile: {
        type: Boolean
      }
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      countOrderNotConformity: null
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    if (this.$store.getters.userIsReseller) {
      this.$store.dispatch('countOrderRequestNotConformityList', {
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      })
        .then((response) => {
         this.countOrderNotConformity = response
        })
        .catch((error) => {
          console.log(error)
        })
    }

  },
  methods: {
    handleCollapsedSidebar(windowWidth) {
      this.showSidebar = windowWidth <= 1069

      this.$emit('resize-collapsed', { showSidebar: this.showSidebar })
    }
  },
  watch: {
    windowWidth: function(windowWith) {
      this.handleCollapsedSidebar(windowWith)
    }
  }
}
</script>
